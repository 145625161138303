import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo.svg"
import { AnchorLink } from 'gatsby-plugin-anchor-links';

class Header extends React.Component {

  render() {
    function toggleMenu() {
      document.querySelector(".navbar-burger").classList.toggle("is-active")
      document.querySelector(".navbar-menu").classList.toggle("is-active")
      console.log("menu pressed")
    }
    function toggleMoreInfo() {
      document.querySelector('#more_info').classList.toggle('is-active');
    }
    function toggleMembership() {
      document.querySelector('#membership').classList.toggle('is-active');
    }
    function toggleOperations() {
      document.querySelector('#operations').classList.toggle('is-active');
    }
    console.log(this.props)
    return (
      <header style={{ padding: '1rem 0' }} className="container">
        <div className="navbar navbar-transparent">
          <div className="navbar-brand  is-hidden-desktop ">
              <Link to="/" className="">
                <figure>
                  <img
                    width="110"
                    src={logo}
                    alt="Westlake Municipal Utiliy District 1"
                    className="logo"
                  />
                </figure>
              </Link>
              <button
                className="button navbar-burger"
                onClick={toggleMenu}
                aria-label="menu"
                aria-expanded="false"
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </button>
          </div>
          <div className="navbar-menu">
            <div className="navbar-brand is-hidden-touch is-adjusted">
              <Link to="/" className="">
                <figure>
                  <img
                    width="110"
                    src={logo}
                    alt="Westlake Municipal Utiliy District 1"
                    className="logo"
                  />
                </figure>
              </Link>
              <button
                className="button navbar-burger"
                onClick={toggleMenu}
                aria-label="menu"
                aria-expanded="false"
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </button>
            </div>
            <div className="navbar-end">
            {/* <a className="navbar-item" href="#">News</a> */}
            <Link className="navbar-item" to="/news">News</Link>
            <AnchorLink className="navbar-item" to="/#success">Our Success</AnchorLink>
            <AnchorLink className="navbar-item" to="/#opportunities">Current Opportunities</AnchorLink>
            <Link className="navbar-item" to="/board-of-directors">Board of Directors</Link>
            <Link className="navbar-item" to="/donate">Make a Donation</Link>
            <Link className="navbar-item" to="/contact">Contact us</Link>
            {/* <Link to="/welcome-home-at-sugar-land-memorial-park" className="button is-info special-link">
              <span><small>Welcome Home Project</small></span></Link> */}
            <a href="https://www.facebook.com/sugarlandlegacy/" className="button is-info social-link">
              <span><small>Find us on:</small>&nbsp;Facebook</span></a>
          </div>
        </div>
        </div>
      </header>
    )
  }
}


export default Header
